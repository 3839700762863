<template>
  <div id="user-list">
    <email-list-add-new v-model="isAddNewUserSidebarActive" @refetch-data="fetchEmails"></email-list-add-new>

    <h2 v-t="'defaultEmailTemplates'" class="text-2xl font-weight-semibold mb-4" />
    <v-row class="mb-5">
      <v-col v-for="defaultEmailData in defaultEmailTemplates" :key="defaultEmailData.title" cols="12" md="4">
        <v-card
          :to="{
            name: 'apps-default-email-template-view',
            params: { action: defaultEmailData.action },
          }"
        >
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div class="w-full">
              <h2 class="font-weight-semibold mb-1">
                {{ defaultEmailData.title }}
              </h2>
              <div class="d-flex justify-space-between align-center">
                <v-chip
                  v-t="resolveEmailActionIcon(defaultEmailData.action).text"
                  label
                  small
                  :color="resolveEmailActionIcon(defaultEmailData.action).color"
                  :class="`v-chip-light-bg ${
                    resolveEmailActionIcon(defaultEmailData.action).color
                  }--text font-weight-semibold text-capitalize ma-1 mb-2`"
                />
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      v-bind="attrs"
                      :to="{
                        name: 'apps-default-email-template-view',
                        params: { action: defaultEmailData.action },
                      }"
                      v-on="on"
                    >
                      <v-icon size="18">
                        {{ icons.mdiEyeOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span v-t="'view'" />
                </v-tooltip>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <h2 v-t="'emailTemplates'" class="text-2xl font-weight-semibold mb-4" />
    <v-card class="mb-12">
      <v-card-title v-t="'searchAndFilter'" />
      <v-row class="px-2 ma-0">
        <v-col cols="12" sm="4">
          <v-select
            v-model="actionFilter"
            :placeholder="$t('selectStatus')"
            :items="choices.actionChoices"
            outlined
            dense
            clearable
            multiple
            hide-details
            :item-text="(item) => $t(item.text)"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="4">
          <v-select
            v-model="languageFilter"
            :placeholder="$t('selectLanguage')"
            :items="choices.languageChoices"
            outlined
            dense
            clearable
            multiple
            hide-details
            :item-text="(item) => $t(item.text)"
          ></v-select>
        </v-col>
      </v-row>

      <v-divider class="mt-4"></v-divider>

      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          :placeholder="$t('search')"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
          @keydown="delaySearchHandler"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn color="primary" class="mb-4 me-3" @click.stop="isAddNewUserSidebarActive = !isAddNewUserSidebarActive">
            <v-icon class="me-2">{{ icons.mdiPlus }}</v-icon>
            <span v-t="'addNewEmailTemplate'" />
          </v-btn>
        </div>
      </v-card-text>

      <v-data-table
        :headers="tableColumns"
        :items="emails"
        :options.sync="options"
        :loading="loading"
        :server-items-length="totalEmailListTable"
        :mobile-breakpoint="0"
        :footer-props="{
          'items-per-page-text': $t('rowsPerPage'),
        }"
      >
        <template #[`top`]>
          <v-dialog v-model="isDialogVisible" max-width="650px" persistent>
            <v-card class="pa-sm-10 pa-3">
              <v-card-title class="justify-center text-h5"> Delete email template </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" class="d-flex align-center justify-center">{{
                    $t('confirmationModalUndoneQuestion')
                  }}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="d-flex justify-center mt-3">
                    <v-btn v-t="'discard'" outlined class="me-3" @click="discardDelete" />
                    <v-btn v-t="'delete'" color="error" @click="confirmDelete" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>
        </template>
        <template #[`header.title`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`header.subject`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`header.lang`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`header.action`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`header.actions`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`item.title`]="{ item }">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name: 'apps-email-template-view', params: { id: item.id } }"
                class="font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.title }}
              </router-link>
            </div>
          </div>
        </template>
        <template #[`item.lang`]="{ item }">
          {{ $t(resolveEmailLanguageText(item.lang)) }}
        </template>

        <template #[`item.action`]="{ item }">
          <v-chip
            label
            small
            :color="resolveEmailActionIcon(item.action).color"
            :class="`v-chip-light-bg ${
              resolveEmailActionIcon(item.action).color
            }--text font-weight-semibold text-capitalize ma-1 mb-2`"
          >
            {{ $t(resolveEmailActionIcon(item.action).text) }}
          </v-chip>
        </template>

        <template #[`item.actions`]="{ item }">
          <div class="d-flex align-center justify-center">
            <v-tooltip bottom>
              <template #activator="tooltipActivator">
                <v-btn
                  icon
                  small
                  color="error"
                  v-bind="tooltipActivator.attrs"
                  v-on="tooltipActivator.on"
                  @click="deleteItem(item.id)"
                >
                  <v-icon size="18">
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span v-t="'delete'" />
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn icon small v-bind="attrs" v-on="on" @click="duplicateHandler(item.id)">
                  <v-icon size="18">
                    {{ icons.mdiContentDuplicate }}
                  </v-icon>
                </v-btn>
              </template>
              <span v-t="'duplicate'" />
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  :to="{ name: 'apps-email-template-view', params: { id: item.id } }"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ icons.mdiEyeOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span v-t="'view'" />
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mdiPlus, mdiDeleteOutline, mdiEyeOutline, mdiContentDuplicate } from '@mdi/js'
import { ref } from '@vue/composition-api'

import { useActions } from 'vuex-composition-helpers'

import EmailListAddNew from './EmailListAddNew.vue'
import useEmailList from './useEmailList'

export default {
  components: {
    EmailListAddNew,
  },
  setup() {
    const isDialogVisible = ref(false)
    const isAddNewUserSidebarActive = ref(false)
    const itemToDeleteId = ref(null)

    const { deleteEmailTemplate, duplicateEmailTemplate } = useActions('jobs', [
      'deleteEmailTemplate',
      'duplicateEmailTemplate',
    ])

    const deleteItem = (itemId) => {
      itemToDeleteId.value = itemId
      isDialogVisible.value = true
    }

    const {
      fetchEmails,
      resolveEmailActionIcon,
      resolveEmailLanguageText,
      delaySearchHandler,

      tableColumns,
      searchQuery,
      totalEmailListTable,
      loading,
      options,
      overview,
      emails,
      choices,
      actionFilter,
      languageFilter,
      defaultEmailTemplates,
    } = useEmailList()

    const confirmDelete = async () => {
      if (itemToDeleteId.value !== null) {
        await deleteEmailTemplate(itemToDeleteId.value)
        await fetchEmails()
      }
      isDialogVisible.value = false
    }
    const discardDelete = () => {
      itemToDeleteId.value = null
      isDialogVisible.value = false
    }

    const duplicateHandler = async (templateId) => {
      await duplicateEmailTemplate(templateId)
      await fetchEmails()
    }

    return {
      fetchEmails,
      resolveEmailActionIcon,
      resolveEmailLanguageText,
      deleteItem,
      confirmDelete,
      discardDelete,
      duplicateHandler,
      delaySearchHandler,

      isDialogVisible,
      itemToDeleteId,
      tableColumns,
      searchQuery,
      totalEmailListTable,
      loading,
      options,
      isAddNewUserSidebarActive,
      overview,
      emails,
      choices,
      actionFilter,
      languageFilter,
      defaultEmailTemplates,

      // icons
      icons: {
        mdiPlus,
        mdiDeleteOutline,
        mdiEyeOutline,
        mdiContentDuplicate,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>

import { getCurrentInstance } from '@vue/composition-api'

export default function emailTemplateUtils() {
  const vm = getCurrentInstance().proxy

  const defaultChoices = {
    actionChoices: [
      {
        text: vm.$t('whenApplied'),
        value: 'applied',
      },
      {
        text: vm.$t('whenMovedToNextStep'),
        value: 'in_progress',
      },
      {
        text: vm.$t('whenHired'),
        value: 'hired',
      },
      {
        text: vm.$t('whenRejected'),
        value: 'rejected',
      },
      {
        text: vm.$t('whenRenewingTalentPoolConsent'),
        value: 'renew_tp_consent',
      },
      {
        text: vm.$t('whenInvitingFromTalentPool'),
        value: 'invite_to_job_tp',
      },
    ],
    languageChoices: [
      {
        text: vm.$t('polish'),
        value: 'pl',
      },
      {
        text: vm.$t('english'),
        value: 'eng',
      },
    ],
  }

  return {
    defaultChoices,
  }
}

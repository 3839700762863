<template>
  <v-navigation-drawer
    :value="isAddNewUserSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.mdAndUp ? '40%' : '100%'"
    app
    @input="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <div class="drawer-header d-flex align-center">
      <span v-t="'addNewEmailTemplate'" class="font-weight-semibold text-base text--primary" />
      <v-spacer></v-spacer>
      <v-btn icon small @click="$emit('update:is-add-new-user-sidebar-active', false)">
        <v-icon size="22">
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div>
    <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
      <v-card-text>
        <v-subheader v-t="'basicData'" />
        <v-text-field
          v-model="emailTemplateData.title"
          outlined
          dense
          maxlength="100"
          :label="$t('title')"
          :placeholder="$t('title')"
          hide-details="auto"
          class="mb-3"
          :rules="[validators.required]"
        ></v-text-field>

        <v-autocomplete
          v-model="emailTemplateData.action"
          :label="$t('action')"
          :placeholder="$t('action')"
          :items="defaultChoices.actionChoices"
          outlined
          dense
          hide-details="auto"
          class="mb-3"
          :rules="[validators.required]"
        ></v-autocomplete>

        <v-autocomplete
          v-model="emailTemplateData.lang"
          :label="$t('language')"
          :placeholder="$t('language')"
          :items="defaultChoices.languageChoices"
          outlined
          dense
          hide-details="auto"
          class="mb-3"
          :rules="[validators.required]"
        ></v-autocomplete>

        <v-subheader v-t="'editor'" />

        <v-text-field
          v-model="emailTemplateData.subject"
          outlined
          dense
          maxlength="150"
          :label="$t('subject')"
          :placeholder="$t('subject')"
          hide-details="auto"
          class="mb-3"
          :rules="[validators.required]"
        ></v-text-field>

        <summer-note
          :key="rerenderSummernoteTrigger"
          :email-variables="emailVariablesSummernote"
          hide-link-and-image-button
          @ready="readySummerNote"
        />
        <div class="float-right text-xs">{{ emailTemplateData.bodyHtml.length }} / 20000</div>
      </v-card-text>
      <v-card-actions class="mt-3">
        <v-row class="overwritten-drawer-actions">
          <v-col cols="12" md="6">
            <v-btn v-t="'discard'" color="secondary" outlined type="reset" block @click="resetEmailTemplateData" />
          </v-col>
          <v-col cols="12" md="6">
            <v-btn
              color="primary"
              class="me-3"
              type="submit"
              :disabled="!valid || !emailTemplateData.bodyHtml.length"
              block
              :loading="loading"
            >
              {{ $t('add') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-form>
  </v-navigation-drawer>
</template>

<script>
import { required } from '@core/utils/validation'

import { mdiClose, mdiTrashCanOutline } from '@mdi/js'
import { ref, getCurrentInstance, watch } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'
import _ from 'lodash'
import SummerNote from '@/plugins/summer-note/SummerNote.vue'

import {
  TEST_EMAIL_VARIABLES_WHEN_STATUS_CHANGED_DATA,
  TEST_EMAIL_VARIABLES_RENEW_TALENT_POOL_CONSENT_DATA,
  TEST_EMAIL_VARIABLES_INVITE_TO_JOB_FROM_TALENT_POOL_DATA,
  EMAIL_VARIABLES_WHEN_STATUS_CHANGED_SUMMERNOTE,
  EMAIL_VARIABLES_RENEW_TALENT_POOL_CONSENT_SUMMERNOTE,
  EMAIL_VARIABLES_INVITE_TO_JOB_FROM_TALENT_POOL_SUMMERNOTE,
} from '@/plugins/summer-note/variables'
import emailTemplateUtils from '@/utils/templates/email/emailTemplateUtils'

const EMAIL_RENEW_TALENT_POOL_CONSENT_ACTION = 'renew_tp_consent'
const EMAIL_INVITE_TO_JOB_FROM_TALENT_POOL_ACTION = 'invite_to_job_tp'

export default {
  components: {
    SummerNote,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy

    const { defaultChoices } = emailTemplateUtils()
    const blankEmailTemplateData = {
      title: '',
      action: null,
      lang: null,
      bodyHtml: '',
      subject: '',
    }

    const { createEmailTemplate } = useActions('jobs', ['createEmailTemplate'])

    const loading = ref(false)

    const valid = ref(false)
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const emailTemplateData = ref(_.cloneDeep(blankEmailTemplateData))

    const resetEmailTemplateData = () => {
      emailTemplateData.value = _.cloneDeep(blankEmailTemplateData)
      resetForm()
      emit('update:is-add-new-user-sidebar-active', false)
    }

    const onSubmit = async () => {
      if (valid.value && emailTemplateData.value.bodyHtml.length) {
        loading.value = true
        await createEmailTemplate({ payload: emailTemplateData.value })
        loading.value = false
        resetEmailTemplateData()
        emit('refetch-data')
      } else {
        validate()
      }
    }

    const editor = ref(null)
    const rerenderSummernoteTrigger = ref(false)
    const testEmailVariables = ref([])
    testEmailVariables.value = TEST_EMAIL_VARIABLES_WHEN_STATUS_CHANGED_DATA(vm)
    const emailVariablesSummernote = ref([])
    emailVariablesSummernote.value = EMAIL_VARIABLES_WHEN_STATUS_CHANGED_SUMMERNOTE(vm)

    const readySummerNote = (initializedEditor) => {
      editor.value = initializedEditor
      editor.value.summernote('code', emailTemplateData.value.bodyHtml)
      editor.value.$on('change', (content) => {
        emailTemplateData.value.bodyHtml = content
      })
    }

    watch(
      () => emailTemplateData.value.action,
      (newValue, oldValue) => {
        if (
          newValue === EMAIL_RENEW_TALENT_POOL_CONSENT_ACTION &&
          oldValue !== EMAIL_RENEW_TALENT_POOL_CONSENT_ACTION
        ) {
          testEmailVariables.value = TEST_EMAIL_VARIABLES_RENEW_TALENT_POOL_CONSENT_DATA(vm)
          emailVariablesSummernote.value = EMAIL_VARIABLES_RENEW_TALENT_POOL_CONSENT_SUMMERNOTE(vm)
          editor.value.summernote('destroy')
          rerenderSummernoteTrigger.value = !rerenderSummernoteTrigger.value
        } else if (
          newValue === EMAIL_INVITE_TO_JOB_FROM_TALENT_POOL_ACTION &&
          oldValue !== EMAIL_INVITE_TO_JOB_FROM_TALENT_POOL_ACTION
        ) {
          testEmailVariables.value = TEST_EMAIL_VARIABLES_INVITE_TO_JOB_FROM_TALENT_POOL_DATA(vm)
          emailVariablesSummernote.value = EMAIL_VARIABLES_INVITE_TO_JOB_FROM_TALENT_POOL_SUMMERNOTE(vm)
          editor.value.summernote('destroy')
          rerenderSummernoteTrigger.value = !rerenderSummernoteTrigger.value
        } else {
          testEmailVariables.value = TEST_EMAIL_VARIABLES_WHEN_STATUS_CHANGED_DATA(vm)
          emailVariablesSummernote.value = EMAIL_VARIABLES_WHEN_STATUS_CHANGED_SUMMERNOTE(vm)
          editor.value.summernote('destroy')
          rerenderSummernoteTrigger.value = !rerenderSummernoteTrigger.value
        }
      },
    )

    return {
      resetEmailTemplateData,
      onSubmit,

      defaultChoices,
      form,
      loading,
      emailTemplateData,
      valid,

      // Summer note
      readySummerNote,
      rerenderSummernoteTrigger,
      editor,
      emailVariablesSummernote,

      // validation
      validators: { required },
      icons: {
        mdiClose,
        mdiTrashCanOutline,
      },
    }
  },
}
</script>
